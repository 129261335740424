import React from "react";

import { ThemeProvider } from "./src/context/ThemeContext";

import "./src/assets/scss/index.scss";
import "./src/css/index.css";

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider>{element}</ThemeProvider>;
};

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
  // console.log("onRouteUpdate :: new pathname", location.pathname);
  // console.log("onRouteUpdate :: window.location.pathname", location);
  // console.log("onRouteUpdate :: old pathname", {
  //   location,
  //   prevLocation
  // });

  if (typeof window !== "undefined") {
    const pageViewInteractionCounter =
      readLocalStorage("pageViewInteraction") ?? [];
    const pageViewCount = readLocalStorage("pageViewCount") ?? 0;

    //add a page tracker
    let pageViewList = [...pageViewInteractionCounter];

    if (!pageViewList?.includes(location.href)) {
      pageViewList.push(location.href);

      writeLocalStorage("pageViewInteraction", JSON.stringify(pageViewList));

      //add a page counter
      const newCount = pageViewCount + 1;
      writeLocalStorage("pageViewCount", newCount.toString());
    }
  }
};

export const onInitialClientRender = (location) => {
  // console.log("onInitialClientRender", location);
  //check for first session in local storage
  const session = readLocalStorage("FirstSession");

  // First time session
  if (session === "undefined" || session === null) {
    writeLogic("FirstSession");
  } else {
    writeLogic("ReturningSession");
  }

  const suggest_country = getCookie("gvx-analytics-suggest-country") || "";
  const site_country = getCookie("gvx-analytics-site-country-code") || "";

  //add location specific data
  writeLocalStorage(
    "gvx-analytics",
    JSON.stringify({
      city: getCookie("gvx-analytics-city") || "",
      country: getCookie("gvx-analytics-country") || "",
      device: getCookie("gvx-analytics-device") || "",
      region: getCookie("gvx-analytics-region") || "",
      timezone: getCookie("gvx-analytics-timezone") || "",
      suggest_country: suggest_country?.toLowerCase(),
      site_country: site_country?.toLowerCase(),
      raw_cookies: {
        firstSession:
          extractCookieStringToObject(getCookie("FirstSession")) || "",
        returningSession:
          extractCookieStringToObject(getCookie("ReturningSession")) || ""
      }
    })
  );
};

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer");
  }
};

function writeLocalStorage(paramName, paramValue) {
  if (typeof window !== "undefined") {
    try {
      window.localStorage.setItem(paramName, paramValue);
    } catch (error) {
      console.error(
        `Error stringifying ${paramValue} for localStorage: `,
        error
      );
    }
  }
}
function getCookie(name) {
  let cookieArray = document.cookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookiePair = cookieArray[i].trim().split("=");
    if (cookiePair[0] === name) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

function extractCookieStringToObject(queryString) {
  return (
    queryString?.split("&").reduce((acc, pair) => {
      const [key, value] = pair.split("=");
      acc[key] = decodeURIComponent(value || "");
      return acc;
    }, {}) || {}
  );
}

function readLocalStorage(paramName) {
  return JSON.parse(
    typeof window !== "undefined"
      ? window.localStorage.getItem(paramName)
      : false
  );
}

function writeLogic(paramName) {
  const sourceDetails = getTrafficSource(paramName);
  if (!sourceDetails) {
    return false;
  }

  if (typeof window !== "undefined") {
    window.localStorage.setItem(paramName, JSON.stringify(sourceDetails));
  }

  return sourceDetails;
}

function getParam(s, q) {
  try {
    var match = s.match("[?&]" + q + "=([^&]+)");
    return match ? match[1] : "";
    //        return s.match(RegExp('(^|&)'+q+'=([^&]*)'))[2];
  } catch (e) {
    return "";
  }
}

function calculateTrafficSource() {
  let ref_domain;
  let ref_search;

  let source = "",
    medium = "",
    campaign = "",
    term = "",
    content = "",
    adgroup = "",
    ttclid = "",
    msclkid = "",
    gclid = "",
    fbclid = "",
    wbraid = "",
    gbraid = "",
    pageviewcount = readLocalStorage("pageViewCount") || 0;

  let search_engines = [
    ["bing", "q"],
    ["google", "q"],
    ["yahoo", "q"],
    ["baidu", "q"],
    ["yandex", "q"],
    ["ask", "q"]
  ]; //List of search engines

  let ref = document?.referrer ? new URL(document?.referrer) : "";
  ref_domain = ref?.hostname || "";
  ref_search = ref?.search || "";

  // Checks for campaign parameters
  let currentHostname = window.location.hostname;
  let page_uri = window.location.href;
  let url_search = window.location.search;

  // Checks for click id parameters, that we want to save all the time
  ttclid = getParam(url_search, "ttclid");
  msclkid = getParam(url_search, "msclkid");
  fbclid = getParam(url_search, "fbclid");
  gclid = getParam(url_search, "gclid");
  wbraid = getParam(url_search, "wbraid");
  gbraid = getParam(url_search, "gbraid");

  if (url_search.indexOf("utm_source") > -1) {
    source = getParam(url_search, "utm_source");
    medium = getParam(url_search, "utm_medium");
    campaign = getParam(url_search, "utm_campaign");
    term = getParam(url_search, "utm_term");
    content = getParam(url_search, "utm_content");
    adgroup = getParam(url_search, "utm_adgroup");
  } else if (url_search.indexOf("gvi_source") > -1) {
    source = getParam(url_search, "gvi_source");
    medium = getParam(url_search, "gvi_medium");
    campaign = getParam(url_search, "gvi_campaign");
    term = getParam(url_search, "gvi_term");
    content = getParam(url_search, "gvi_content");
  } else if (gclid || wbraid || gbraid) {
    source = "g";
    medium = getParam(url_search, "utm_medium") || "cpc";
    campaign = getParam(url_search, "utm_campaign") || "(not set)";
  } else if (fbclid) {
    source = "fb";
    medium = getParam(url_search, "utm_medium") || "cpc";
    campaign = getParam(url_search, "utm_campaign") || "(not set)";
  } else if (msclkid) {
    source = "bing";
    medium = getParam(url_search, "utm_medium") || "cpc";
    campaign = getParam(url_search, "utm_campaign") || "(not set)";
  } else if (ttclid) {
    source = "tiktok";
    medium = getParam(url_search, "utm_medium") || "cpc";
    campaign = getParam(url_search, "utm_campaign") || "(not set)";
  } else if (ref) {
    if (ref_domain !== currentHostname) {
      medium = "referral";
      source = ref_domain;
    }

    if (ref_domain === currentHostname) {
      return false;
    }

    // Extract term for organic source
    for (let i = 0; i < search_engines.length; i++) {
      if (ref_domain.indexOf(search_engines[i][0]) > -1) {
        medium = "organic";
        source = search_engines[i][0];
        term = getParam(ref_search, search_engines[i][1]) || "(not provided)";
        break;
      }
    }
  }

  pageviewcount = pageviewcount++; //getParam(url_search, 'pageviewcount')
  const userAgent = navigator?.userAgent;

  return {
    uri: page_uri,
    source,
    medium,
    campaign,
    term,
    content,
    adgroup,
    gclid,
    msclkid,
    ttclid,
    fbclid,
    wbraid,
    gbraid,
    pageviewcount,
    userAgent
  };
}

function getTrafficSource(cookieName, hostname) {
  const trafficSources = calculateTrafficSource();

  if (!trafficSources) {
    return false;
  }

  let source =
    trafficSources.source.length === 0 ? "direct" : trafficSources.source;

  if (
    trafficSources.source.length === 0 &&
    (trafficSources.campaign.length === 0 ||
      trafficSources.gclid.length === 0 ||
      trafficSources.gbraid.length === 0 ||
      trafficSources.wbraid.length === 0 ||
      trafficSources.fbclid.length === 0 ||
      trafficSources.msclkid.length === 0 ||
      trafficSources.ttclid.length === 0)
  ) {
    source = "-";
  }

  const source_url =
    trafficSources.uri.length === 0 ? "direct" : trafficSources.uri;
  const medium =
    trafficSources.medium.length === 0 ? "none" : trafficSources.medium;
  const campaign =
    trafficSources.campaign.length === 0 ? "direct" : trafficSources.campaign;

  // console.log("trafficSources >>>> ", trafficSources);

  return Object.fromEntries(
    Object.entries({
      ...trafficSources,
      source: source,
      uri: source_url,
      medium: medium,
      campaign: campaign,
      pageviewcount: trafficSources?.pageviewcount || 0,
      date: new Date().toISOString().slice(0, 10).replace(/-/g, "")
    }).filter(([key, value]) => value !== "" && value != null)
  );
}
